<template>
  <div class="flex justify-center">
    <img
      src="https://res.cloudinary.com/btbassets/image/upload/v1614770507/icons/loading.svg"
      width="80"
      alt=""
    />
  </div>
</template>

<script>
export default {}
</script>

<style></style>
