<script>
import OpenInNew from 'btb-common_styles/src/svg-icons/open-in-new.svg'
export default {
  data() {
    return {
      applicationsUrl: process.env.VUE_APP_APPLICATIONS_URL,
      monespaceInstance: process.env.VUE_APP_MONESPACE_INSTANCE_URL
    }
  },
  components: {
    OpenInNew
  },
  methods: {
    logout() {
      this.$keycloakLogout()
    }
  }
}
</script>

<template>
  <div class="legacy-account">
    <div>
      <p>
        Ce nouvel espace personnel remplacera d'ici peu Mon Espace. Tu seras
        informée dès qu'il sera disponible !
      </p>
      <p><b>D'ici là, continue de te connecter ici :</b></p>
      <a
        target="_blank"
        :href="monespaceInstance"
        class="mt-5 btn btn-light btn--small"
      >
        Mon Espace
        <OpenInNew
          class="ml-2"
          width="16px"
          height="16px"
          viewBox="0 0 22 22"
        />
      </a>
      <a
        target="_blank"
        :href="applicationsUrl"
        class="mt-5 ml-5 btn btn-light btn--small"
      >
        Workplace
        <OpenInNew
          class="ml-2"
          width="16px"
          height="16px"
          viewBox="0 0 22 22"
        /> </a
      ><br />
      <a class="mt-5 block cursor" @click="logout()">Déconnexion</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.legacy-account {
  height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url('./../assets/legacy-backgrounds/large.png');
  > div {
    background: white;
    position: absolute;
    min-width: 380px;
    padding: $spacer-large;
    border-radius: $radius-large;
    border: 2px solid $accent-color;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  a {
    cursor: pointer;
  }
  @media screen and (max-width: 1200px) {
    background-image: url('./../assets/legacy-backgrounds/1200px.png');
  }
  @media screen and (max-width: 1023px) {
    background-image: url('./../assets/legacy-backgrounds/1023px.png');
  }
  @media screen and (max-width: 767px) {
    background-image: url('./../assets/legacy-backgrounds/767px.png');
  }
  @media screen and (max-width: 575px) {
    background-image: url('./../assets/legacy-backgrounds/575px.png');
  }
  @media screen and (max-width: 420px) {
    background-image: url('./../assets/legacy-backgrounds/420px.png');
  }
}
</style>
