import * as backendClient from '@/services/backend.api'

const initialState = () => {
  return {
    profile: {},
    isLegacyAccount: false,
    moneyback: {},
    isStripeSessionAvailable: false
  }
}

const profileStore = {
  namespaced: true,
  state: initialState,
  mutations: {
    STORE_PROFILE: (
      state,
      { profile, isStripeSessionAvailable, moneyback }
    ) => {
      state.profile = { ...state.profile, ...profile }
      state.isStripeSessionAvailable = isStripeSessionAvailable
      state.moneyback = moneyback
    }
  },
  actions: {
    getProfile: async ({ dispatch }) => {
      try {
        const response = await backendClient.getProfile()
        dispatch('storeProfile', {
          profile: response.profile,
          isStripeSessionAvailable: response.isStripeSessionAvailable,
          moneyback: response.moneyback
        })
      } catch (error) {
        dispatch(
          'setError',
          {
            isError: true,
            status: error?.response?.data?.errors[0]?.status,
            message: error?.response?.data?.errors[0]?.message,
            code: error?.response?.data?.errors[0]?.code
          },
          { root: true }
        )
        throw error
      } finally {
        dispatch('setIsLoadingState', false, { root: true })
      }
    },
    updateAProfile: async ({ dispatch }, { data }) => {
      const profile = await backendClient.updateAProfile(data)
      dispatch('storeProfile', {
        profile: profile
      })
    },
    storeProfile: (
      { commit, state },
      {
        profile,
        isStripeSessionAvailable = state.isStripeSessionAvailable,
        moneyback = state.moneyback
      }
    ) => {
      commit('STORE_PROFILE', {
        profile,
        isStripeSessionAvailable,
        moneyback
      })
    }
  }
}

export default profileStore
