import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'account',
    component: () => import('@/views/account/AccountIndex.vue'),
    meta: {
      title: 'Mon Compte | Bouge ta Boite'
    }
  },
  {
    path: '/browsers',
    name: 'browsers',
    component: () => import('@/views/errors/browserError.vue'),
    meta: {
      title: "Votre navigateur n'est plus pris en charge | Bouge ta Boite"
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/errors/404Error.vue'),
    meta: {
      title: 'Erreur 404 | Bouge ta Boite'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = 'Bouge ta Boite'
router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta.title || DEFAULT_TITLE
  next()
})

export default router
