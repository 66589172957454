<script>
export default {}
</script>

<template>
  <footer>
    <ul>
      <li>
        <a target="_blank" href="https://redirections.bougetaboite.com/r/cgu"
          >CGU</a
        >
      </li>
      <li>
        <a target="_blank" href="https://redirections.bougetaboite.com/r/cgv"
          >CGUS</a
        >
      </li>
      <li>
        <a
          target="_blank"
          href="https://redirections.bougetaboite.com/r/donnees_personnelles"
          >Données personnelles</a
        >
      </li>
      <li>
        <a
          target="_blank"
          href="https://redirections.bougetaboite.com/r/mentions_legales"
          >Mentions légales</a
        >
      </li>
    </ul>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-alpha;
  margin-top: $spacer-medium-plus;
  margin-bottom: 6rem;
  padding: $spacer-medium $spacer-small;
}
ul {
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
}
li {
  position: relative;
  list-style-type: none;
  display: inline-block;
  margin-left: $spacer-tiny-plus;
  padding: 0 $spacer-tiny;
  &:not(:last-child) {
    margin-right: $spacer-tiny-plus;
    &:after {
      position: relative;
      content: '|';
      right: -10px;
    }
  }
}
a {
  color: $dark-blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
