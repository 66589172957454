import { store } from '@/store'

export async function backendAuth({ logout }) {
  try {
    const me = await store.dispatch('getMe')
    // Les utilisatrices 'nouveau monde' ont un customerUID et legacyAccount == false
    if (!me || !me.customerUid || me.legacyAccount) {
      // Si ce n'est pas le cas, mise en store d'une var isLegacyAccount
      // pour affichage spécifique au niveau de App.vue
      store.state.profileStore.isLegacyAccount = true
      store.dispatch('setIsLoadingState', false)
    } else {
      store.dispatch('profileStore/getProfile')
    }
  } catch (error) {
    logout()
  }
}
