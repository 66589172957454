<script>
import supportedBrowsers from './supportedBrowsers'
import BaseHeader from '@/components/BaseHeader.vue'
import BaseFooter from '@/components/BaseFooter.vue'
import BaseError from '@/components/BaseError.vue'
import BaseBanner from '@/components/BaseBanner.vue'
import LoadingState from '@/components/LoadingState.vue'
import LegacyAccount from '@/components/LegacyAccount.vue'

export default {
  components: {
    BaseHeader,
    BaseFooter,
    LoadingState,
    BaseBanner,
    BaseError,
    LegacyAccount
  },
  data() {
    return {
      pathsWhereHeaderIsNotVisible: [
        /*'/signup', '/signup-confirmation'*/
      ],
      showHeader: true
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.isLoading
      }
    },
    error: {
      get() {
        return this.$store.state.error
      }
    },
    isLegacyAccount: {
      get() {
        return this.$store.state.profileStore.isLegacyAccount
      }
    }
  },
  methods: {
    isHeaderVisible: function (to = null) {
      if (
        this.pathsWhereHeaderIsNotVisible.indexOf(this.$route.path) >= 0 ||
        this.pathsWhereHeaderIsNotVisible.indexOf(to?.path) >= 0
      ) {
        this.showHeader = false
      } else {
        this.showHeader = true
      }
    }
  },
  async beforeCreate() {
    this.$store.dispatch('setIsLoadingState', true, { root: true })
  },
  async created() {
    if (!supportedBrowsers.test(navigator.userAgent)) {
      this.$router.replace({ name: 'browsers' })
    }
    this.isHeaderVisible()
  },
  watch: {
    $route(to) {
      this.isHeaderVisible(to)
    }
  }
}
</script>

<template>
  <!-- S'il s'agit d'un compte "ancien monde", affichage spécifique -->
  <LegacyAccount v-if="isLegacyAccount" />
  <div v-else>
    <BaseHeader v-if="showHeader" />

    <BaseBanner />

    <main
      class="layout-maxed"
      :class="{
        'header-is-diplayed': showHeader
      }"
    >
      <transition-group name="fade">
        <LoadingState v-if="isLoading" key="loading" />
        <BaseError
          v-else-if="error.isError"
          key="error"
          :status="error.status"
          :message="error.message"
          :code="error.code"
        />
        <router-view key="content" v-else />
      </transition-group>
    </main>

    <BaseFooter />
  </div>
</template>

<style lang="scss">
html {
  overflow-y: auto;
}
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  main {
    flex-grow: 1;
  }
}
main {
  padding: 0;

  &.header-is-diplayed {
    padding: 50px 0 0 0;
    @media screen and (min-width: 576px) {
      padding: 65px 0 0 0;
    }
    ::v-deep .low-navbar {
      display: block;
    }
  }
}
.layout-maxed {
  max-width: 620px;
}

input:not(.checkbox),
select,
textarea {
  background-color: $ternary-background-light;
  &:focus {
    background-color: $secondary-background-light;
  }
}
.radio {
  box-shadow: inset 0 0 0 1px $secondary-background-dark;
  &:checked {
    background-color: $accent-color;
  }
}

hr {
  color: $secondary-background-light;
}
.tooltip-inner {
  background: $ternary-background-light;
  color: $color-gray-6;
  .tooltip-arrow {
    background: $ternary-background-light;
  }
}
.low-navbar {
  border-top: 1px solid $color-gray-2;
}
.fade-enter-active {
  transition: opacity 0.4s ease;
}
.fade-leave-active {
  transition: opacity 0s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
