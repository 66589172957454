<script>
export default {
  props: {
    status: {
      type: Number
    },
    message: {
      type: String
    },
    code: {
      type: String
    }
  }
}
</script>

<template>
  <section>
    <h1 class="mt-7">Une erreur à été rencontrée</h1>
    <div>
      <p class="mt-6 mb-0" v-if="status || code">{{ status }} {{ code }}</p>
      <h2 v-if="message">{{ message }}</h2>
    </div>
  </section>
</template>

<style lang="scss" scoped>
h1 {
  color: $accent-color;
}
</style>
