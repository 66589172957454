import omitBy from 'lodash/omitBy'
const initialState = () => {
  return {
    bannerAlerts: [
      // {
      //   id: '1',
      //   level: 'warning',
      //   message:
      //     'Mew is said to possess the genetic composition of all Pokémon.',
      //   linkUrl: '#',
      //   linkText: 'Lorem ipsum dolor',
      //   isDismissable: true
      // },
      // {
      //   id: '2',
      //   level: 'error',
      //   message:
      //     'Mew is said to possess the genetic composition of all Pokémon.',
      //   linkUrl: '#',
      //   linkText: 'Lorem ipsum dolor',
      //   isDismissable: true
      // }
    ]
  }
}

const notificationStore = {
  namespaced: true,
  state: initialState,
  mutations: {
    CLEAR_BANNER_ALERT: (state, id) => {
      state.bannerAlerts = omitBy(state.bannerAlerts, function (val) {
        return val.id === id
      })
    }
  },
  actions: {
    clearAlert: ({ commit }, id) => {
      commit('CLEAR_BANNER_ALERT', id)
    }
  }
}

export default notificationStore
