import axios from 'axios'
import { createToaster } from '@meforma/vue-toaster'

const frenchGovAddressesClient = axios.create({
  baseURL: `https://api-adresse.data.gouv.fr/search`,
  headers: {
    'Content-Type': 'application/json'
  }
})

const toaster = createToaster({
  /* options */
})

frenchGovAddressesClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    toaster.error(error.message)

    throw error
  }
)

export { frenchGovAddressesClient }
