<script>
import ChevronDown from 'btb-common_styles/src/svg-icons/chevron-down.svg'
import ChevronUp from 'btb-common_styles/src/svg-icons/chevron-up.svg'
import Logout from 'btb-common_styles/src/svg-icons/logout.svg'
export default {
  methods: {
    toggleUserNav() {
      this.$store.dispatch('toggleUserNav')
    },
    logout: function () {
      this.$keycloakLogout()
    }
  },
  components: {
    ChevronDown,
    ChevronUp,
    Logout
  },
  computed: {
    userNavIsOpen() {
      return this.$store.state.userNavIsOpen
    },
    profile: {
      get() {
        return this.$store.state.profileStore.profile
      }
    }
  }
}
</script>

<template>
  <div class="account accountJSHolder">
    <button
      class="cursor-pointer flex align-items-center"
      @click="toggleUserNav"
    >
      <span class="mr-2">{{ profile.firstName }}</span>
      <ChevronDown
        viewBox="0 0 24 24"
        v-show="!userNavIsOpen"
        class="chevron"
      />
      <ChevronUp viewBox="0 0 24 24" v-show="userNavIsOpen" class="chevron" />
    </button>
    <ul
      class="account-sidebar white-bg accountJSHolder"
      :class="{ 'is-open': userNavIsOpen }"
      @click="toggleUserNav"
    >
      <li>
        <button @click="logout()">
          Déconnexion<Logout width="16px" height="16px" viewBox="0 0 22 22" />
        </button>
      </li>
    </ul>
    <div v-if="userNavIsOpen" class="outside" @click="toggleUserNav"></div>
  </div>
</template>

<style scoped lang="scss">
.account {
  position: relative;
}
.account-sidebar {
  position: absolute;
  display: none;
  right: 0;
  margin-top: 15px;
  width: 250px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 0;
  z-index: 1;
  li {
    list-style-type: none;
    a,
    button {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-align: left;
      padding: $spacer-tiny-plus $spacer-medium;
      cursor: pointer;
    }
  }
  &.is-open {
    display: block;
  }
}
.icon-chevron-down {
  &::before {
    font-size: 1.2rem;
  }
}
hr {
  margin: 1rem 0;
}
.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}
.chevron {
  width: 30px;
  height: 30px;
}

.account-sidebar {
  a {
    color: $color-gray-4;
  }
  li:hover {
    background: $color-gray-2;
    a {
      color: $color-gray-4;
    }
  }
}
</style>
