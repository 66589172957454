import { createStore } from 'vuex'
import * as backendClient from '@/services/backend.api'
import profileStore from './profile.store'
import notificationStore from './notification.store'

const initialState = () => {
  return {
    me: {},
    userNavIsOpen: false,
    isLoading: false,
    keycloakToken: null,
    error: {
      isError: false
    }
  }
}

export const store = createStore({
  modules: {
    profileStore,
    notificationStore
  },
  state: initialState,
  mutations: {
    TOGGLE_USER_NAV: async (state) => {
      state.userNavIsOpen = !state.userNavIsOpen
    },
    SET_IS_LOADING: async (state, value) => {
      state.isLoading = value
    },
    SET_KEYCLOAK_TOKEN: (state, token) => {
      state.keycloakToken = token
    },
    SET_ERROR: (state, { isError, status, message, code }) => {
      state.error = {
        isError,
        status,
        message,
        code
      }
    },
    SET_ME: (state, me) => {
      state.me = me
    }
  },
  actions: {
    toggleUserNav: ({ commit }) => {
      commit('TOGGLE_USER_NAV')
    },
    setIsLoadingState: ({ commit }, value) => {
      commit('SET_IS_LOADING', value)
    },
    setKeycloakToken: ({ commit }, token) => {
      commit('SET_KEYCLOAK_TOKEN', token)
    },
    setError: (
      { commit },
      { isError, status = null, message = null, code = null }
    ) => {
      commit('SET_ERROR', { isError, status, message, code })
    },
    getMe: async ({ commit, dispatch }) => {
      try {
        const me = await backendClient.getMe()
        commit('SET_ME', me)
        return me
      } catch (error) {
        dispatch(
          'setError',
          {
            isError: true,
            status: error?.response?.data?.errors[0]?.status,
            message: error?.response?.data?.errors[0]?.message,
            code: error?.response?.data?.errors[0]?.code
          },
          { root: true }
        )
        dispatch('setIsLoadingState', false)
        throw error
      }
    }
  }
})
