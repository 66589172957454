import { backendClient } from '@/services/network'
import * as mappers from '@/services/mappers'

export async function getMe() {
  const response = await backendClient({
    method: 'GET',
    url: `/me`
  })
  if (response?.data?.me) {
    return response.data.me
  } else {
    return response
  }
}

export async function getProfile() {
  const response = await backendClient({
    method: 'GET',
    url: `/me/profile`
  })
  const domain = mappers.profileToDomain(response.data.profile)
  return {
    profile: domain,
    isStripeSessionAvailable: response.data.options.scp.show,
    moneyback: response.data.options.moneyback
  }
}

export async function updateAProfile(data) {
  const response = await backendClient({
    method: 'PATCH',
    url: `/me/profile`,
    data: data
  })
  const domain = mappers.profileToDomain(response.data.profile)
  return domain
}

export async function getStripeSessionUrl() {
  const response = await backendClient({
    method: 'POST',
    url: `/me/stripe-sessions`
  })
  return response
}

export async function postToMoneybackBeacon() {
  const response = await backendClient({
    method: 'POST',
    url: `/me/beacons/moneyback2022-nexturl-click`
  })
  return response
}
