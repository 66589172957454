export function profileToDomain(profileDTO) {
  const domain = {
    uid: profileDTO.uid,
    firstName: profileDTO.firstName,
    lastName: profileDTO.lastName,
    email: profileDTO.email,

    phonePrefix: profileDTO.phonePrefix,
    phoneNumber: profileDTO.phoneNumber,
    positionTypeInCompany: profileDTO.positionTypeInCompany,
    refereeCode: profileDTO.refereeCode,

    companyName: profileDTO.companyName,
    companyIsFetched: profileDTO.companyIsFetched,
    companyAddressCity: profileDTO.companyAddressCity,
    companyAddressLabel: profileDTO.companyAddressLabel,
    companyAddressCountryISOAlpha2Code:
      profileDTO.companyAddressCountryISOAlpha2Code,
    companyAddressLatitude: profileDTO.companyAddressLatitude,
    companyAddressLine1: profileDTO.companyAddressLine1,
    companyAddressLine2: profileDTO.companyAddressLine2,
    companyAddressLongitude: profileDTO.companyAddressLongitude,
    companyAddressState: profileDTO.companyAddressState,
    companyAddressZipcode: profileDTO.companyAddressZipcode,
    companyCustomName: profileDTO.companyCustomName,
    companyRegistrationDate: profileDTO.companyRegistrationDate,
    companyRegistrationNumber: profileDTO.companyRegistrationNumber,
    companyTaxIdentifier: profileDTO.companyTaxIdentifier,
    companyTaxIdentifierType: profileDTO.companyTaxIdentifierType,

    hasGandiReference: profileDTO.hasGandiReference,
    hasKeycloakReference: profileDTO.hasKeycloakReference,
    hasStripeReference: profileDTO.hasStripeReference,
    hasWorkplaceReference: profileDTO.hasWorkplaceReference,

    historyFirstSubscriptionCompletionDate:
      profileDTO.historyFirstSubscriptionCompletionDate,

    login: profileDTO.login,
    legalLastGTOSValidationDate: profileDTO.legalLastGTOSValidationDate,
    legalLastPublicityRightsValidationDate:
      profileDTO.legalLastPublicityRightsValidationDate,

    sourceCategory: profileDTO.sourceCategory,
    sourceOrigin: profileDTO.sourceOrigin
  }
  return domain
}
