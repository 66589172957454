<script>
import Close from 'btb-common_styles/src/svg-icons/close.svg'
export default {
  components: {
    Close
  },
  methods: {
    clearAlert: function (id) {
      this.$store.dispatch('notificationStore/clearAlert', id)
    }
  },
  computed: {
    bannerAlerts: {
      get() {
        return this.$store.state.notificationStore.bannerAlerts
      }
    }
  }
}
</script>

<template>
  <div class="banners-container">
    <TransitionGroup name="slide-up">
      <div
        v-for="alert in bannerAlerts"
        :key="alert.id"
        class="banner flex justify-between"
        :class="`alert-${alert.level}`"
      >
        <p class="mb-0">
          {{ alert.message }}
          <a :href="alert.linkUrl">{{ alert.linkText }}</a>
        </p>

        <button
          v-if="alert.isDismissable"
          class="close-button p-0"
          @click="clearAlert(alert.id)"
        >
          <Close viewBox="0 0 24 24" width="24px" height="24px" />
        </button>
      </div>
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
.banners-container {
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 58px;
}
.banner {
  color: white;
  position: relative;
  top: 0;
  padding: $spacer-medium;
  &.alert-warning {
    background: $color-alternate-3;
    .close-button {
      color: $color-alternate-3b;
    }
  }
  &.alert-error {
    background: $color-alternate-4;
    .close-button {
      color: $color-alternate-4b;
    }
  }
  a {
    color: white;
    font-weight: 600;
  }
  .close-button {
    cursor: pointer;
    transition: color 0.2s ease;
    &:hover {
      color: white;
    }
  }
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.6s ease;
}
.slide-up-enter-from,
.slide-up-leave-to {
  top: -140px;
}
</style>
