<script>
import UserNav from '@/components/UserNav.vue'
import Apps from 'btb-common_styles/src/svg-icons/apps.svg'
export default {
  data() {
    return {
      applicationsUrl: process.env.VUE_APP_APPLICATIONS_URL
    }
  },
  components: {
    UserNav,
    Apps
  }
}
</script>

<template>
  <header class="white-bg flex justify-between">
    <router-link to="/" class="logo"></router-link>
    <div class="flex-grow">
      <section class="flex justify-end align-items-center top-header">
        <a target="_blank" :href="applicationsUrl" class="mr-2 btn btn--small"
          ><Apps viewbox="0 0 18 18" class="mx-2" />Mes applications</a
        >|
        <UserNav class="ml-1" />
      </section>
    </div>
  </header>
</template>

<style scoped lang="scss">
header {
  position: fixed;
  width: 100%;
  margin-top: 1px;
  z-index: 10;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  .layout-maxed {
    position: static;
  }
}
.btn {
  letter-spacing: 0;
  > * {
    align-items: middle;
  }
}
.navbar {
  padding: $spacer-tiny-plus 0;
  transition: ease-in padding 0.2s;
  a {
    text-decoration: none;
  }
  span {
    margin-right: $spacer-small;
    vertical-align: middle;
    &:before {
      font-size: 1.8rem;
    }
  }
}
.top-header {
  padding: $spacer-small;
  a {
    text-decoration: none;
  }
}
.logo {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: $spacer-small $spacer-tiny $spacer-tiny;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('https://res.cloudinary.com/btbassets/image/upload/v1702457318/logos/logo-page-_nationale.png');
  transition: ease-in height 0.2s;
  @media screen and (min-width: 480px) {
    margin-left: 20px;
  }
}
</style>
