import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'
import { store } from '@/store'
import { createToaster } from '@meforma/vue-toaster'

const backendClient = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_API}/v1`,
  headers: {
    'content-type': 'application/json'
  }
})

loadProgressBar(null, backendClient)

const toaster = createToaster({
  /* options */
})

backendClient.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${store.state.keycloakToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

backendClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.data?.errors) {
      for (const singleError of error.response.data.errors) {
        toaster.error(singleError.message)
      }
      // transmission de l'erreur pour une potentielle gestion au niveau des vues
      throw error
    } else {
      toaster.error('Une erreur est survenue')
      throw error
    }
  }
)

export { backendClient }
